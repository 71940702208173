<template>
  <section v-if="isGetCollectionRequestPending">
    <p-loading />
  </section>
  <section v-else class="organizations">
    <div class="organization-list-wrapper">
      <div class="header-wrapper">
        <div>
          <router-link v-if="$hasPermission('organizations')" to="organizations/new">+&nbsp;add new</router-link>
        </div>
        <div><label>Total:</label>&nbsp;{{ total }}</div>
      </div>
      <ul class="organization-list">
        <li class="organization-list-item header">
          <!-- <div>Id</div> -->
          <div>Name</div>
          <div>Aliases</div>
          <div>Domains</div>
          <div>Created At</div>
          <div>Updated At</div>
          <div class="action-link-wrapper"></div>
        </li>
      </ul>
      <ul class="organization-list">
        <li v-for="entry in collection" :key="entry.id" class="organization-list-item">
          <!-- <div>{{ entry.id }}</div> -->
          <div>{{ entry.name }}</div>
          <div>
            <ul>
              <li v-for="item of entry.aliases" :key="entry.id + item">
                <label>{{ item }}</label>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li v-for="item of entry.domains" :key="entry.id + item">
                <label>{{ item }}</label>
              </li>
            </ul>
          </div>
          <div>{{ entry.createdAt && new Date(entry.createdAt).toLocaleString() }}</div>
          <div>{{ entry.updatedAt && new Date(entry.updatedAt).toLocaleString() }}</div>
          <div class="action-link-wrapper">
            <router-link :to="`organizations/${entry.id}/edit`">&#9998;</router-link>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/common/Loading';

export default {
  components: {
    'p-loading': Loading
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      total: s => s.organizations.collection.length,
      collection: s => s.organizations.collection,
      isGetCollectionRequestPending: s => s.organizations.isGetCollectionRequestPending
    })
  },
  watch: {
    $route: {
      handler() {
        this.$store.dispatch('organizations/getCollection');
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.organizations {
  display: grid;
  padding: 1rem 1rem 0 1rem;
  // width: 100%;

  .organization-list-wrapper {
    display: grid;
    grid-template-rows: 20px max-content minmax(0, 1fr);

    .header-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 0.75rem;
      margin: 0;
      padding: 0;
      font-weight: 400;
      margin-right: 6px;
      label {
        font-weight: 500;
      }

      > * {
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .organization-list {
    margin: 0;
    padding: 0;
    list-style: none;

    .organization-list-item {
      display: grid;
      grid-template-columns: minmax(250px, 1fr) 150px 250px 150px 150px 30px;
      grid-gap: 0;
      border-bottom: 1px solid var(--theme-highlight);
      font-size: 0.75rem;

      > * {
        padding: 0.5rem 0.5rem;
        border-left: 1px solid var(--theme-highlight);

        &:last-child {
          border-right: 1px solid var(--theme-highlight);
        }
      }

      &:first-child {
        border-top: 1px solid var(--theme-highlight);
      }

      &.header {
        font-size: 0.75rem;
        color: var(--theme-on-background-accent);
        > * {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0.25rem 0.5rem;
        }
      }

      .action-link-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left-color: transparent;

        > * {
          opacity: 0;
        }
      }

      &:hover {
        .action-link-wrapper {
          > * {
            opacity: 1;
          }
        }
      }

      .more-info {
        font-style: italic;
        color: var(--theme-on-background-accent);
      }
    }

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:last-child {
      overflow-y: scroll;
    }
  }
}
</style>
